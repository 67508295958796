@import "../../vars.css";

.navbar{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #a2f1af;
    padding-top: 50px;
    padding-bottom: 5px;
    width: 100%;
    height: 10vh;
       
    
}

.title{
    margin-left: 10%;
    font-size: 30px;
    font-weight: 600px;
    text-decoration: none;
    color: black;
    animation: animate 1s ease-in-out;
}
@keyframes animate {
    0%{
        transform: translateY(-100px);
    }
    100%{
        transform: translateY(0px);
    }
}

.menu{
    display: flex;
    animation: animate 1s ease-in-out;
}
@keyframes animate {
    0%{
        transform: translateY(-100px);
    }
    100%{
        transform: translateY(0px);
    }
}

.menuItems{
    display: flex;
    gap: 45px;

    list-style: none;
}

.menuItems li:last-child{
    padding-right: 70px;
}
.menuItems a{
    font-size: 25px;
    color: black;
    text-decoration: none;
}

.menuBtn{
    display: none;
}


@media screen and (max-width: 1000px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
      }
    
      .menu {
        position: absolute;
        right: 0;
        margin-right: 10%;
        flex-direction: column;
        align-items: flex-end;
        gap: 11px;
        z-index: 3;
      }
    
      .menuBtn {
        display: block;
        cursor: pointer;
        width: 30px;
        height: 30px;
        
      }
    
      .menuItems {
        display: none;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        border-radius: 10px;
        background: rgba(162, 241, 175, 0.6);
        backdrop-filter: blur(5px);
        padding: 24px 33px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
      }

      .menuItems li:last-child{
        padding-right: 0;
    }
    
      .menuOpen {
        display: flex;
        z-index: 1;
      }
}

