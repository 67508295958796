@import "../../vars.css";

.container{
    position: relative;
    padding: 73px;
    margin-top: 150px;
    z-index: 1;
    background-color: #FBFBF6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.title{
    display: flex;
    justify-content: center;
    text-decoration: solid;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    margin-bottom: 50px;
    color: #85c790;

}
.content{
    display: flex;
    justify-content: center;

}

.services{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    animation: animates 10s linear infinite;
    
  }

.serviceItem{
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 20px;
    width: 250px;
    height: 280px;
    margin-right: 20px;
    border-radius: 20px;
    font-family: var(--font-family);
    color: white;
    transition: 0.3s ease-in-out;
    animation: animate 0.8s ease-in-out 1;
}

@keyframes animate {
    from{
        transform: rotateY(90deg);
    }
    to{
        transform: rotateY(0deg);
    }
    
}
.serviceItem:hover{
    position: relative;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border: 0;
    transform: translateY(-20px);

}




.serviceItem:hover h2 {
   text-shadow: black;
}

.serviceItem h2{
    position: relative;
    font-size: 30px;
    font-weight: 600;
    color: white;
    z-index: 1;
    
}

.serviceItem p{
    font-size: 25px;
}

.serviceItem img{
   width: 350px;
   z-index: 1;
}

.container a{
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    margin-top: 50px;
    padding: 0 18px;
    padding-top: 5px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
.container a:hover{
    background-color: #fff;
}
.container a:active{
    box-shadow: #422800 2px 2px 0 0;
    transform: translate(2px, 2px);
}

.fontStyle{
    font-family: var(--font-family);
}

@media screen and (max-width: 1000px) {
    
    .container{
        margin-top: 150px;
        padding: 35px;
    }
    .container a{
        padding: 0 10px;
        padding-top: 5px;
        line-height: 40px;
    }
    .serviceItem{
        width: 270px;
    }
    .serviceItem h3{
        font-size: 18px;
    }
    
    .serviceItem p{
        font-size: 18px;
    }
    
}