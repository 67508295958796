@import "../../vars.css";

.container{
    padding: 73px;
    margin-top: 150px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom,  rgb(0 0 0 / 15%), rgb(0 0 0 / 15%)),url("../../assets/customers.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

}

.title{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    color: #FBFBF6;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

.reviewList{
    color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 50px;
    
}

.reviewItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    list-style: none;
    margin-top: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgb(203, 197, 197);
    backdrop-filter: blur(5px);
    margin-left: 10%;
    margin-right: 10%;
    width: 500px;
    border-radius: 20px;
    transition: 0.3s;
}
.reviewItem:hover{
    
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    backdrop-filter: blur(5px);
    transform: translateY(-20px);
}

.reviewComment{
    text-align: center;
    font-size: 20px;
    font-style: italic;
    padding-top: 5px;
    padding-bottom: 5px;
}


.reviewAuthor{
    font-weight: bolder;
    font-size: 20px;
}

@media screen and (max-width: 1000px) {
    
    .container{
        margin-left: 0;
        margin-right: 0;
        margin-top: 150px;
        padding: 35px;
        background-attachment: scroll;
    }
    .reviewItem{
        width:100%;
    }
    .reviewItem:hover{
        font-size: 25px;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    
    
}

@media screen and (max-width: 700px){
    .container{
        background-image: none;
        background-color: #FBFBF6;
    }
    .title{
        color: #85c790;
    }
    
}

