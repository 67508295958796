@import "../../vars.css";

.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 15px;
    z-index: 1;
    margin-top: 150px;
    margin-left: 10%;
    margin-right: 10%;
}

.title{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    color: black;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    font-family: var(--font-roboto);
}
.content{
    border-radius: 20px; 
    padding: 25px;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    transition: 0.3s;
}
.content:hover {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transform: translateY(-5px);
}
.phone a, .email a{
    text-decoration: none;
}
.phone, .address, .email{
    display: flex;
    align-items: center;
    margin-top: 10px;
    
    font-family: var(--font-roboto);
    font-size: large;
}
.phone img, .address img, .email img{
    width:25px;
    height:25px;
    margin-right: 5px;
}
.hours{
    margin-top: 10px;
    font-family: var(--font-roboto);
    font-size: large;
}
.hours p{
    padding-bottom: 10px;
}

.subTitle{
    margin-top: 20px;
    font-family: var(--font-roboto);
    font-weight: 700px;
    font-size: 25px;
}
.text{
    margin-top: 10px;
    font-family: var(--font-roboto);
    font-size: 20px;
    
}

.wrapper{
    display: flex;
    width: 100%;
    height: 100%;
}
.map iframe{
    width: 40%;
    margin-left: 10%;
    height: 50%;
    position: absolute;
    border-radius: 20px;
    border: 0;
    transition: 0.3s;
}
.map iframe:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transform: translateY(-5px);
}

.upBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 0;

}

@media screen and (max-width: 1000px) {
    
    .container{
        margin-left: 5%;
        margin-right: 5%;
        background-image: none;
        margin-top: 150px;
        margin-bottom: 0;
        padding: 0;
    }
    .content{
        width: 100%;
        
    }
    .wrapper{
        display: flex;
        margin-left: 0;
        flex-direction: column;
        width: 100%;
    }
    .map iframe{
        margin-top: 10%;
        margin-left: 0;
        width: 100%;
        min-height: 200px;
        min-width: auto;
        position: unset;
    }
}