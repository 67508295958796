@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100..800&display=swap');

:root{
    --color-text: #fff;
    --color-primary: #60a02c;
    --color-secondary: #40691e;
    --color-dark: #253f0f;
    --color-bg: #152408;

    --font-family: "Anek Telugu", sans-serif;
}