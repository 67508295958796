@import '../../vars.css';

body{
    overflow-x: hidden;
}
.container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    z-index: 1;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 300px;
    padding-bottom: 200px;
    background-image: linear-gradient(to bottom,  rgb(0 0 0 / 30%), rgb(0 0 0 / 30%)),url("../../assets/store1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: center;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-text);
    z-index: 1;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 15px;
    padding-top: 15px;
}

.content h1{
    width: 50%;
    font-size: 72px;
    font-size: 5rem;
    font-family: var(--font-roboto);
    color: white;
    padding-bottom: 20px;
    animation: animate 1s ease-in-out;
}

.container p{
    width: 50%;
    font-size: 2.3rem;
    font-family: var(--font-roboto);
    color: white;
    margin-top: 20px;
    margin-bottom: 50px;
    font-style: italic;
    animation: animate 1s ease-in-out;
}
@keyframes animate {
    0%{
        transform: translateY(-100px);
    }
    100%{
        transform: translateY(0px);
    }
}
.container a{
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 18px;
    padding-top: 5px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
.container a:hover{
    background-color: #fff;
}
.container a:active{
    box-shadow: #422800 2px 2px 0 0;
    transform: translate(2px, 2px);
}

.image{
    width: 50%;
    border-radius: 20%;
    z-index: 1;

    animation: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0%{
        transform: translate(0, 0px);
    }
    50%{
        transform: translate(0, 10px);
    }
    100%{
        transform: translate(0, -0px);
    }
}


@media screen and (max-width: 1000px) {

    .container{
        padding-top: 150px;
        padding-bottom: 100px;
    }

    .content h1{
        display: flex;
        justify-content: center;
        font-size: 30px;
        padding-bottom: 0;
        padding-top: 20px;
        width: 100%;
        font-size: 3.5rem;
    }
    .content p{
        font-size: 1.5rem;
        margin-bottom: 20px;
    }
    .content a{
        padding: 0 10px;
        padding-top: 5px;
        line-height: 35px;
        margin-bottom: 20px
    }
    
}

 @media screen and (max-width: 650px) {
    .container{
        padding-top: 50px;
        padding-bottom: 50px;
        background-position: center;
    }

    .content h1{
        display: flex;
        justify-content: center;
        font-size: 30px;
        padding-bottom: 0;
        padding-top: 20px;
        width: 100%;
        font-size: 2.3rem;
    }
    .content p{
        font-size: 0.9rem;
        margin-bottom: 20px;
    }
    .content a{
        padding: 0 10px;
        padding-top: 5px;
        line-height: 35px;
        margin-bottom: 20px
    }
    
}