@import "../../vars.css";
@import "../../../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css";


.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 73px;
    z-index: 1;
    margin-top: 150px;
    /*margin-left: 10%;
    margin-right: 10%;*/
    background-color: #FBFBF6;
    /*background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    box-shadow: 0 0 0 2px rgba(218,102,123,1), 8px 8px 0 0 rgba(218,102,123,1);*/
    transition: 1s;
}


.appointmentForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    font-family: var(--font-roboto);
    color: rgb(133, 199, 144);
}


input[type="text"]{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 10px;
    width: 50%;
    background-color: rgb(255, 255, 255, 0);
    border: none;
    border-bottom: 2px solid black;
    border-radius: 1px;
    font-family: var(--font-roboto);
    font-size: large;
}
input[type="text"]::placeholder{
    color: black;
    font-family: var(--font-roboto);
    font-size: large;
}
input[type="text"]:hover{
    color: rgb(13, 135, 34);
    border-bottom: 2px solid rgb(13, 135, 34);
}
input[type="text"]:hover::placeholder{
    color: rgb(13, 135, 34);
}

:global .react-datepicker-wrapper{
    width: 100%;
}

.datepicker{
    width: 50%;
    
}
.datepicker input[type="text"]{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    color: black;
    font-family: var(--font-roboto);
    font-size: large;
    background-color: rgb(255, 255, 255, 0);
    border: none;
    border-bottom: 2px solid black;
    border-radius: 1px; 
}
.datepicker input[type="text"]:hover{
    color: rgb(13, 135, 34);
    border-bottom: 2px solid rgb(13, 135, 34);
}
.datepicker input[type="text"]:hover::placeholder{
    color: rgb(13, 135, 34);
}



input[type="submit"]{
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    padding: 0 18px;
    padding-top: 5px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
input[type="submit"]:hover{
    background-color: #fff;
}
input[type="submit"]:active{
    box-shadow: #422800 2px 2px 0 0;
    transform: translate(2px, 2px);
}

.errorMessage{
    color: red;
}

:global .react-datepicker__time-list-item--disabled{
    display: none;
}

@media screen and (max-width: 1000px) {
    
    .container{
        margin-top: 150px;
        padding: 35px;
    }
   
    input[type="text"]{
        width: 100%;
    }
    
    input[type="submit"]{
        padding: 0 10px;
        padding-top: 5px;
        line-height: 40px;
    }
    .datepicker{
        width: 100%;
    }
}